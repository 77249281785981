<template>
  <div>
    <b-row class="match-height">
      <b-col v-if="$Can('settings_onboarding')" xl="4" md="4" sm="6">
        <settings-card
          icon="SettingsIcon"
          statistic="Onboarding Checklist"
          link="onboarding"
          statistic-title="Onboarding Checklist"
          color="success"
        />
      </b-col>

      <b-col v-if="$Can('settings_permissions')" xl="4" md="4" sm="6">
        <settings-card
          icon="ShieldIcon"
          statistic="Roles & Permissions"
          link="userrole"
          statistic-title="User Roles"
          color="success"
        />
      </b-col>

      <b-col xl="4" md="4" sm="6">
        <settings-card
          icon="ClockIcon"
          statistic="Reminder Tasks"
          link="reminder-tasks"
          statistic-title="Reminder Tasks"
          color="success"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormGroup,
  BTable,
  BRow,
  BCol,
  BFormSelect,
  BPagination,
} from "bootstrap-vue";

import useUserList from "./useSettings";

import store from "@/store";
import { avatarText } from "@core/utils/filter";
import { onUnmounted } from "@vue/composition-api";
import settingsStoreModule from "./settingsStoreModule";
import SettingsCard from "@core/components/statistics-cards/SettingsCard.vue";
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BTable,
    BFormSelect,
    BPagination,

    SettingsCard,
  },

  methods: {},

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = "settings";
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
      store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
        store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });

    const { loading } = useUserList();

    return {
      loading,
      // Filter
    };
  },
  mounted() {},
};
</script>
