import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('user', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchSettings(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('settings', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    updateSetting(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateSetting/1`, data)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    

    fetchOnboarding(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('fetchOnboarding', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    updateOnboardingName(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateOnboardingName', queryParams )
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    updateOnboardingType(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateOnboardingType', queryParams )
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    deleteOnboarding(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('deleteOnboarding', queryParams )
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },


    

    /********* Roles */
    /*********** */
    fetchRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('roles', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
    fetchPermissions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('permissions', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
    addRole(ctx, role) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('roles/store', role)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateRole(payload) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`roles/${payload.role}`, payload.permissions)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },
  },
};
